import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor() {}

  confirm(
    title: string,
    text: string,
    confirmButtonText: string = 'Yes',
    cancelButtonText: string = 'No'
  ): Promise<boolean> {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    }).then((result) => result.isConfirmed);
  }

  confirmActivateItem(
    text: string = 'Are you sure you want to activate this item?'
  ): Promise<boolean> {
    return this.confirm('Activate Item', text, 'Activate', 'Cancel');
  }

  confirmDeactivateItem(
    text: string = 'Are you sure you want to deactivate this item?'
  ): Promise<boolean> {
    return this.confirm('Deactivate Item', text, 'Deactivate', 'Cancel');
  }

  confirmDeleteItem(
    text: string = 'Do you really want to delete this item?'
  ): Promise<boolean> {
    return this.confirm('Delete Item', text, 'Yes, delete it!', 'Cancel');
  }
}
